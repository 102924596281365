import { FC } from "react";
import { classNames } from "@utils/classNames";
import Link from "next/link";

interface Props {
  cN?: string;
  label?: string;
}

export const PostJobButton: FC<Props> = ({ cN, label = "Post job" }) => (
  <Link
    href="/job-posts/create"
    className={classNames(
      "inline-flex items-center px-2 h-10 border border-transparent leading-4 tracking-tight font-medium",
      cN
    )}
  >
    {label}
  </Link>
);
