import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const de = [
  {
    path: "/de",
    parentPath: "/",
    relatedPaths: ["/remote/de"],
    page: {
      header: {
        title: `JavaScript jobs in Germany in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Germany on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Germany",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:de",
    },
  },
  {
    path: "/de/berlin",
    parentPath: "/de",
    relatedPaths: ["/de/frankfurt", "/remote/de"],
    page: {
      header: {
        title: `JavaScript jobs in Berlin, Germany in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Berlin, Germany on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Berlin, Germany",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:de, locality_long_name:berlin",
      remoteLocationTags: "country_code:de",
    },
  },
  {
    path: "/de/frankfurt",
    parentPath: "/de",
    relatedPaths: ["/de/berlin", "/remote/de"],
    page: {
      header: {
        title: `JavaScript jobs in Frankfurt, Germany in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Frankfurt, Germany on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Frankfurt, Germany",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:de, locality_long_name:frankfurt",
      remoteLocationTags: "country_code:de",
    },
  },
] as SeoPage[];
