export enum SiteId {
  JobsInJsCom = "JobsInJsCom",
  GoogleCom = "GoogleCom",
  LinkedInCom = "LinkedInCom",
  IndeedCom = "IndeedCom",
  AngelListCo = "AngelListCo",
  TotalJobsCom = "TotalJobsCom",
  CvLibraryCoUk = "CvLibraryCoUk",
  HireRemoteIo = "HireRemoteIo",
  WeWorkRemotelyCom = "WeWorkRemotelyCom",
  WorkInStartupsCom = "WorkInStartupsCom",
  ReedCoUk = "ReedCoUk",
  EFinancialCareersCoUk = "EFinancialCareersCoUk",
  TechnoJobsCoUk = "TechnoJobsCoUk",
  JobServeCom = "JobServeCom",
}

export enum UserType {
  JobsInJsComBot = "JobsInJsComBot",
  JobsInJsComCrawler = "JobsInJsComCrawler",
  JobsInJsComEditor = "JobsInJsComEditor",
  JobsInJsComUser = "JobsInJsComUser",
}

export enum ProductType {
  NewListing = "NewListing",
  ListingPromotion = "ListingPromotion",
}

export enum ActionType {
  CreateJob = "CreateJob",
  CreateJobPaymentSuccessful = "CreateJobPaymentSuccessful",
  PromoteJob = "PromoteJob",
}

export enum Ir35Status {
  Inside = "Inside",
  Outside = "Outside",
}

export enum Page {
  JobDoesNotExist = "JobDoesNotExist",
  JobNoLongerAvailable = "JobNoLongerAvailable",
  PageNotFound = "PageNotFound",
  PostNewJob = "PostNewJob",
}

export interface EmploymentTypeOption {
  label: string;
  url: string;
  isActive: boolean;
  className?: string;
}

export enum ActionStatus {
  Success = "Success",
  Error = "Error",
}

export interface GeoPoint {
  lat: number;
  lng: number;
}

export interface CustomParams {
  [key: string]: any;
}

export interface CustomMap<V> {
  [key: string]: V;
}

export interface OpenGraph {
  title: string;
  description: string;
  url: string;
}

export enum LocationType {
  ONSITE = "ONSITE",
  ONSITE_AND_REMOTE = "ONSITE_AND_REMOTE",
  REMOTE = "REMOTE",
}
