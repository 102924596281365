import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const it = [
  {
    path: "/it",
    parentPath: "/",
    relatedPaths: ["/remote/it"],
    page: {
      header: {
        title: `JavaScript jobs in Italy in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Italy on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Italy",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:it",
      remoteLocationTags: "country_code:it",
    },
  },
  {
    path: "/it/rome",
    parentPath: "/it",
    relatedPaths: ["/remote/it"],
    page: {
      header: {
        title: `JavaScript jobs in Rome, Italy in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Rome, Italy on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Rome, Italy",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:it, locality_long_name:rome",
      remoteLocationTags: "country_code:it",
    },
  },
  {
    path: "/it/milan",
    parentPath: "/it",
    relatedPaths: ["/remote/it"],
    page: {
      header: {
        title: `JavaScript jobs in Milan, Italy in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Milan, Italy on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Milan, Italy",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:it, locality_long_name:milan",
      remoteLocationTags: "country_code:it",
    },
  },
] as SeoPage[];
