import React, { FC } from "react";
import TwitterIcon from "@public/social-icons/twitter.svg";
import LinkedInIcon from "@public/social-icons/linkedin.svg";
import InstagramIcon from "@public/social-icons/instagram.svg";
import Link from "next/link";
import { onsite } from "data/seoPages/onsite";
import { general } from "data/seoPages/general";
import { collections } from "data/seoPages/collections";

const FooterLink = ({ href, children }) => (
  <Link href={href} className="block text-gray-400 hover:text-gray-500">
    {children}
  </Link>
);

// capitalize first letter in string
const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const Footer: FC = () => (
  <div className="mt-20 pt-10 pb-20 border-t">
    <div className="max-w-3xl mx-auto">
      <div className="flex flex-col justify-between mb-10 text-xs leading-6 sm:flex-row">
        <div className="mx-4 my-4 space-y-1.5">
          <h2 className="font-medium uppercase">Categories</h2>
          <ul>
            {general.map((seoPage) => (
              <li key={seoPage.path}>
                <FooterLink href={seoPage.path}>
                  {seoPage.page.body.heading?.replace(
                    "hiring around the World",
                    ""
                  )}
                </FooterLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-4 my-4 space-y-1.5">
          <h2 className="font-medium uppercase">Locations</h2>
          <ul>
            {onsite.map((seoPage) => (
              <li key={seoPage.path}>
                <FooterLink href={seoPage.path} key={seoPage.path}>
                  {seoPage.page.body.heading
                    ?.replace("JavaScript jobs hiring in the", "")
                    ?.replace("JavaScript jobs hiring in", "")}
                </FooterLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-4 my-4 space-y-1.5">
          <h2 className="font-medium uppercase">Collections</h2>
          <ul>
            {collections.map((seoPage) => (
              <li key={seoPage.path}>
                <FooterLink href={seoPage.path}>
                  {capitalize(
                    seoPage.page.body.heading
                      ?.replace("hiring JavaScript developers", "")
                      .replace("JavaScript", "")
                      .trim()
                  )}
                </FooterLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-4 my-4 space-y-1.5">
          <div className="font-medium uppercase">General</div>
          <FooterLink href="/">Home</FooterLink>
          <FooterLink href="/hiring">Hire JavaScript Developers</FooterLink>
          <FooterLink href="/job-posts/create">Post your job</FooterLink>
          <FooterLink href="/about">About</FooterLink>
        </div>
      </div>
      <div className="flex flex-row justify-center mx-auto my-4 space-x-4 text-xs text-gray-300 ">
        &copy; 2019-2022 JobsinJS.com. All Rights Reserved.
      </div>
      <div className="flex flex-row justify-center mx-auto space-x-4">
        <a href="https://twitter.com/jobsinjs" target="_blank">
          <TwitterIcon
            className="fill-current text-gray-200 hover:text-gray-400 h-6"
            title="Twitter"
          />
        </a>
        <a href="https://www.linkedin.com/company/jobs-in-js/" target="_blank">
          <LinkedInIcon
            className="fill-current text-gray-200 hover:text-gray-400 h-6"
            title="LinkedIn"
          />
        </a>
        <a href="https://www.instagram.com/jobsinjs/" target="_blank">
          <InstagramIcon
            className="fill-current text-gray-200 hover:text-gray-400 h-6"
            title="Instagram"
          />
        </a>
      </div>
      <div className="mt-10 text-center ">
        <div className="text-xs text-gray-300">More projects by me</div>
        <a
          href="https://czechcourse.com/"
          className="text-blue-600 hover:text-blue-700"
        >
          <strong>Czech Course</strong>: Learn Czech language online
        </a>
      </div>
    </div>
  </div>
);
