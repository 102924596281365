import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const uk = [
  {
    path: "/uk",
    parentPath: "/",
    relatedPaths: ["/remote/uk"],
    page: {
      header: {
        title: `JavaScript jobs in the United Kingdom in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in the United Kingdom on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in the United Kingdom",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:gb",
      remoteLocationTags: "country_code:gb",
    },
  },
  {
    path: "/uk/london",
    parentPath: "/uk",
    relatedPaths: ["/remote/uk"],
    page: {
      header: {
        title: `JavaScript jobs in London, UK in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in London, UK on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in London, UK",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:gb, locality_long_name:london",
      remoteLocationTags: "country_code:gb",
    },
  },
] as SeoPage[];
