import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const pt = [
  {
    path: "/pt",
    parentPath: "/",
    relatedPaths: ["/remote/pt"],
    page: {
      header: {
        title: `JavaScript jobs in Portugal in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Portugal on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Portugal",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:pt",
      remoteLocationTags: "country_code:pt",
    },
  },
  {
    path: "/pt/lisbon",
    parentPath: "/pt",
    relatedPaths: ["/pt/porto", "/remote/pt"],
    page: {
      header: {
        title: `JavaScript jobs in Lisbon, Portugal in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Lisbon, Portugal on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Lisbon, Portugal",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:pt, locality_long_name:lisbon",
      remoteLocationTags: "country_code:pt",
    },
  },
  {
    path: "/pt/porto",
    parentPath: "/pt",
    relatedPaths: ["/pt/lisbon", "/remote/pt"],
    page: {
      header: {
        title: `JavaScript jobs in Porto, Portugal in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Porto, Portugal on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Porto, Portugal",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:pt, locality_long_name:porto",
      remoteLocationTags: "country_code:pt",
    },
  },
] as SeoPage[];
