import { format } from "date-fns";
import { SeoPage } from "../seoPages";
import { regions as regionsData } from "data/regions";

export const regions = [
  {
    path: "/north-america",
    parentPath: "/",
    relatedPaths: regionsData.NORTH_AMERICA.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in North America in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in North America on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in North America",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.NORTH_AMERICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.NORTH_AMERICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
  {
    path: "/south-america",
    parentPath: "/",
    relatedPaths: regionsData.SOUTH_AMERICA.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in South America in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in South America on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in South America",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.SOUTH_AMERICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.SOUTH_AMERICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
  {
    path: "/europe",
    parentPath: "/",
    relatedPaths: regionsData.EUROPE.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in Europe in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Europe on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Europe",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.EUROPE.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.EUROPE.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
  {
    path: "/africa",
    parentPath: "/",
    relatedPaths: regionsData.ASIA.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in Africa in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Africa on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Africa",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.AFRICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.AFRICA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
  {
    path: "/asia",
    parentPath: "/",
    relatedPaths: regionsData.ASIA.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in Asia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Asia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Asia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.ASIA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.ASIA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
  {
    path: "/oceania",
    parentPath: "/",
    relatedPaths: regionsData.OCEANIA.countries.map(
      (country) => `country_code:${country.toLowerCase()}`
    ),
    page: {
      header: {
        title: `JavaScript jobs in Oceania in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Oceania on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Oceania",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: regionsData.OCEANIA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags: regionsData.OCEANIA.countries
        .map((country) => `country_code:${country.toLowerCase()}`)
        .join(", "),
      remoteLocationTagsMinimumMatch: 1,
    },
  },
] as SeoPage[];
