import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const fr = [
  {
    path: "/fr",
    parentPath: "/",
    relatedPaths: ["/remote/fr"],
    page: {
      header: {
        title: `JavaScript jobs in France in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in France on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in France",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:fr",
      remoteLocationTags: "country_code:fr",
    },
  },
  {
    path: "/fr/paris",
    parentPath: "/fr",
    relatedPaths: ["/remote/fr"],
    page: {
      header: {
        title: `JavaScript jobs in Paris, France in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Paris, France on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Paris, France",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:fr, locality_long_name:paris",
      remoteLocationTags: "country_code:fr",
    },
  },
] as SeoPage[];
