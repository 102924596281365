import { Menu, Transition } from "@headlessui/react";
import { FC, forwardRef, Fragment } from "react";
import { Session } from "@auth0/nextjs-auth0";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "@utils/classNames";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Link from "next/link";

interface Props {
  user: Session["user"];
}

const MenuButton = () => (
  <Menu.Button className="flex text-sm focus:outline-none" title="menu">
    <div className="flex items-center h-12 -mr-2 pl-2 pr-1 rounded hover:bg-gray-100 lg:h-10">
      <UserCircleIcon className="block h-8 w-8 text-black sm:h-6 sm:w-6" />
      <KeyboardArrowDownIcon className="block h-4 w-4 mt-0.5 -ml-1 text-black" />
    </div>
  </Menu.Button>
);

// Note: The Next.js Link component does not forward unknown props to the underlying a element, so it won't close the menu on click when used inside a Menu.Item.
// To use a Next.js Link inside a Menu.Item, create your own component that wraps Link and forwards unknown props to the child a element.
// https://headlessui.dev/react/menu#integrating-with-next-js
const CustomLink = forwardRef((props: any, ref) => {
  let { href, children, cN = "", ...rest } = props;
  return (
    <Link
      href={href}
      ref={ref}
      className={classNames("block px-4 py-2 text-black hover:bg-gray-100", cN)}
      {...rest}
    >
      {children}
    </Link>
  );
});

export const SecondaryNavigation: FC<Props> = ({ user }) => {
  return (
    <Menu as="div" className="ml-2 relative">
      {({ open }) => (
        <>
          <MenuButton />
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right fixed right-0 mt-2 left-0 text-lg tracking-tight shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none lg:absolute lg:left-auto lg:w-64 lg:text-sm lg:rounded-md"
            >
              {user ? (
                <>
                  <div className="px-4 py-3 text-xs text-gray-500">
                    <p className="">Signed in as</p>
                    <p className="text-black truncate" title={user.email}>
                      {user.email}
                    </p>
                  </div>
                  <div className="border-b"></div>
                  <Menu.Item>
                    <CustomLink href="/user">Dashboard</CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink href="/user/job-posts/my">
                      My job posts
                    </CustomLink>
                  </Menu.Item>
                  <Menu.Item>
                    <CustomLink href="/user/orders">Orders</CustomLink>
                  </Menu.Item>
                  <div className="border-b"></div>
                </>
              ) : (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/api/auth/login"
                        className="block px-4 py-2 font-bold text-black hover:bg-gray-100"
                      >
                        Sign up
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/api/auth/login"
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        Log in
                      </a>
                    )}
                  </Menu.Item>
                  <div className="border-b"></div>
                </>
              )}
              {/* Only display on small screens (where primary menu is hidden) */}
              <div className="block lg:hidden">
                <Menu.Item>
                  <CustomLink href="/">Find work</CustomLink>
                </Menu.Item>
                <Menu.Item>
                  <CustomLink href="/hiring">Hire devs</CustomLink>
                </Menu.Item>
                <Menu.Item>
                  <CustomLink href="/companies">Companies</CustomLink>
                </Menu.Item>
                <Menu.Item>
                  <CustomLink href="/job-posts/create" cN="font-bold">
                    Post a job
                  </CustomLink>
                </Menu.Item>
                <div className="border-b"></div>
              </div>
              <Menu.Item>
                <CustomLink href="/about">About Jobs in JS</CustomLink>
              </Menu.Item>

              {user ? (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/api/auth/logout"
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        Log out
                      </a>
                    )}
                  </Menu.Item>
                </>
              ) : null}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
