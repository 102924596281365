import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { LocationType } from "types/misc";
import { useRouter } from "next/router";
import { JobPostSearchParams } from "@services/elasticsearch/utils/getJobPostSearchParamsFromServerQuery";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import dynamic from "next/dynamic";
import { HeaderSearchModalProps } from "./HeaderSearchModal";

const DynamicModal = dynamic<HeaderSearchModalProps>(
  () => import("./HeaderSearchModal").then((mod) => mod.HeaderSearchModal),
  {
    ssr: false,
    // loading: () => <div>'Loading...'</div>,
  }
);

export const HeaderSearch = ({}) => {
  const router = useRouter();

  // Modal.
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Keyboard shortcuts - listen for keys down cmd+k.
  const onKeyDown = (e) => {
    if (e.metaKey && e.which === 75) {
      setOpen(!open);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  // Get search params from url.
  const searchParams = router.query as unknown as JobPostSearchParams;
  const emptyFormValues = {
    search: "",
    location: "",
    locationType: undefined,
    tags: [],
  };
  const formValues = {
    ...emptyFormValues,
    ...searchParams,
    tags: Array.isArray(searchParams.tags)
      ? searchParams.tags.filter((t) => !!t)
      : [searchParams.tags].filter((t) => !!t),
  };
  const { search, location, locationType, tags } = formValues;

  const numberOfActiveFilters = () => {
    let count = 0;
    if (
      locationType === LocationType.ONSITE ||
      locationType === LocationType.REMOTE
    ) {
      count++;
    }
    count += tags.length;
    return count;
  };

  return (
    <>
      {/* Button that looks like the search input. */}
      <button
        type="button"
        style={{ width: isSmallScreen ? 240 : 400 }}
        className="relative pl-1 pr-16 py-4 text-left text-sm text-gray-400 truncate overflow-hidden rounded-md bg-gray-100 border hover:bg-gray-200 focus-visible:outline-none sm:pr-28"
        onClick={() => {
          setOpen(true);
        }}
      >
        {/* Search icon. */}
        <SearchIcon
          fontSize={isSmallScreen ? "small" : "medium"}
          className="-mt-1 ml-1 mr-1 text-gray-400"
        />
        {/* Search and location. */}
        {search && location ? (
          <span>
            <span className="text-black font-semibold">{search}</span>
            {" in "}
            <span className="text-black font-semibold">{location}</span>
          </span>
        ) : search ? (
          <span>
            <span className="text-black font-semibold">{search}</span>{" "}
            <span className="">anywhere</span>
          </span>
        ) : location ? (
          <span>
            <span className="">All jobs</span>
            {" in "}
            <span className="text-black font-semibold">{location}</span>
          </span>
        ) : (
          "Search by title, skill or company..."
        )}

        {/* Number of active filters. */}
        {numberOfActiveFilters() > 0 && (
          <span className="absolute right-2 px-1 uppercase font-semibold tracking-tight text-xxs text-white rounded bg-gray-800 sm:right-10">
            {numberOfActiveFilters() === 1
              ? `1 filter`
              : `${numberOfActiveFilters()} filters`}
          </span>
        )}

        {/* Keyboard shortcut. */}
        <span className="hidden absolute right-2 -mt-px p-1 text-xxs text-gray-400 font-medium border border-gray-300 rounded leading-none sm:inline">
          <KeyboardCommandKeyIcon fontSize="inherit" />
          <span className="relative top-px">K</span>
        </span>
      </button>

      {/* Modal. */}
      {/* @ts-ignore:next-line */}
      <DynamicModal
        formValues={formValues}
        emptyFormValues={emptyFormValues}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
