import Link from "next/link";
import { useRouter } from "next/router";
import { classNames } from "@utils/classNames";

export const PrimaryNavigation = () => {
  const router = useRouter();
  return (
    <div className="hidden ml-6 font-medium tracking-tight xl:flex sm:space-x-4 ">
      <Link
        href="/"
        className={classNames(
          router.pathname === "/" ? "text-black" : "text-black",
          "inline-flex items-center px-1"
        )}
      >
        Find Work
      </Link>
      <Link
        href="/hiring"
        className={classNames(
          router.pathname === "/hiring" ? "text-black" : "text-black",
          "inline-flex items-center px-1"
        )}
      >
        Hire Devs
      </Link>
      <Link
        href="/companies"
        className={classNames(
          router.pathname === "/companies" ? "text-black" : "text-black",
          "inline-flex items-center px-1"
        )}
      >
        Companies
      </Link>
    </div>
  );
};
