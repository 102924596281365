import { FC, ReactNode } from "react";
import Head from "next/head";
import Script from "next/script";
import { useUser } from "@auth0/nextjs-auth0";
import { Header } from "@components/Layout/Header/Header";
import { BodyLayout } from "@components/Layout/BodyLayout";
import { Footer } from "@components/Layout/Footer";

interface Props {
  pageTitle?: string;
  metaDescription?: string;
  canonicalUrl?: string;
  hasRobotsNoIndex?: boolean;
  children?: ReactNode;
  bodyCN?: string;
  hasSearch?: boolean;
}

export const Layout: FC<Props> = ({
  pageTitle,
  metaDescription,
  canonicalUrl,
  hasRobotsNoIndex,
  children,
  bodyCN,
  hasSearch,
}) => {
  // Auth0
  const { user, error, isLoading } = useUser();
  // if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {hasRobotsNoIndex && <meta name="robots" content="noindex" />}
        <link rel="icon" href="/favicon.svg" />
      </Head>

      {/* Global site tag (gtag.js) - Google Analytics ga4 version */}
      {/* - this needs to be initiated early so we can start collecting events. */}
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
        `}
      </Script>
      {/* - this is lazy-loaded in order to increase page performance.  */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
        strategy="lazyOnload"
      />

      {/* Hotjar Tracking Code  */}
      {process.env.NEXT_PUBLIC_HOTJAR_ID &&
        process.env.NEXT_PUBLIC_HOTJAR_SV && (
          <>
            {/* - this needs to be initiated early so we can start collecting events. */}
            {/* <Script id="hotjar" strategy="afterInteractive">
              {`(function(h,o) {
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid: parseInt(${process.env.NEXT_PUBLIC_HOTJAR_ID}),hjsv:parseInt(${process.env.NEXT_PUBLIC_HOTJAR_SV})};
                })(window,document);`}
            </Script> */}
            {/* - this is lazy-loaded in order to increase page performance.  */}
            {/* <Script
              src={`https://static.hotjar.com/c/hotjar-${process.env.NEXT_PUBLIC_HOTJAR_ID}.js?sv=${process.env.NEXT_PUBLIC_HOTJAR_SV}`}
              strategy="lazyOnload"
            /> */}
          </>
        )}

      <main className="min-h-screen">
        <div className="fixed top-0 left-0 z-50 w-full h-20 bg-white">
          <Header user={user} hasSearch={hasSearch} />
        </div>
        <div className="pt-20">
          <BodyLayout cN={bodyCN}>{children}</BodyLayout>
        </div>
        <Footer />
      </main>
    </>
  );
};
