import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const eu = [
  {
    path: "/eu",
    parentPath: "/",
    relatedPaths: ["/remote/eu"],
    page: {
      header: {
        title: `JavaScript jobs in the European Union in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in the European Union on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in the European Union",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags:
        "country_code:at, country_code:be, country_code:bg, country_code:cy, country_code:cz, country_code:de, country_code:dk, country_code:ee, country_code:es, country_code:fi, country_code:fr, country_code:gr, country_code:hr, country_code:hu, country_code:ie, country_code:it, country_code:lt, country_code:lu, country_code:lv, country_code:mt, country_code:nl, country_code:pl, country_code:pt, country_code:ro, country_code:se, country_code:si, country_code:sk",
      onsiteLocationTagsMinimumMatch: 1,
      remoteLocationTags:
        "country_code:at, country_code:be, country_code:bg, country_code:cy, country_code:cz, country_code:de, country_code:dk, country_code:ee, country_code:es, country_code:fi, country_code:fr, country_code:gr, country_code:hr, country_code:hu, country_code:ie, country_code:it, country_code:lt, country_code:lu, country_code:lv, country_code:mt, country_code:nl, country_code:pl, country_code:pt, country_code:ro, country_code:se, country_code:si, country_code:sk",
      remoteLocationTagsMinimumMatch: 1,
    },
  },
] as SeoPage[];
