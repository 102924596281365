import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const be = [
  {
    path: "/be",
    parentPath: "/",
    relatedPaths: ["/remote/be"],
    page: {
      header: {
        title: `JavaScript jobs in Belgium in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Belgium on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Belgium",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:be",
      remoteLocationTags: "country_code:be",
    },
  },
  {
    path: "/be/brussels",
    parentPath: "/be",
    relatedPaths: ["/remote/be"],
    page: {
      header: {
        title: `JavaScript jobs in Brussels, Belgium in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Brussels, Belgium on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Brussels, Belgium",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:be, locality_long_name:brussels",
      remoteLocationTags: "country_code:be",
    },
  },
] as SeoPage[];
