import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const au = [
  {
    path: "/au",
    parentPath: "/",
    relatedPaths: ["/au/sydney", "/au/melbourne", "/remote/au"],
    page: {
      header: {
        title: `JavaScript jobs in Australia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Australia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Australia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:au",
      remoteLocationTags: "country_code:au",
    },
  },
  {
    path: "/au/sydney",
    parentPath: "/au",
    relatedPaths: ["/au/melbourne", "/remote/au"],
    page: {
      header: {
        title: `JavaScript jobs in Sydney, Australia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Sydney, Australia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Sydney, Australia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:au, locality_long_name:sydney",
      remoteLocationTags: "country_code:au",
    },
  },
  {
    path: "/au/melbourne",
    parentPath: "/au",
    relatedPaths: ["/au/sydney", "/remote/au"],
    page: {
      header: {
        title: `JavaScript jobs in Melbourne, Australia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Melbourne, Australia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Melbourne, Australia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:au, locality_long_name:melbourne",
      remoteLocationTags: "country_code:au",
    },
  },
] as SeoPage[];
