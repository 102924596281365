import { FC, ReactNode } from "react";
import { classNames } from "../../utils/classNames";

interface Props {
  children?: ReactNode;
  cN?: string;
}

export const BodyLayout: FC<Props> = ({ children, cN }) => (
  <div className={classNames("max-w-screen-2xl m-auto mt-8", cN)}>
    {children}
  </div>
);
