import { format } from "date-fns";
import { SeoPage } from "./seoPages";

export const general = [
  {
    path: "/",
    parentPath: undefined,
    relatedPaths: [],
    maxRelatedPages: 10,
    page: {
      header: {
        title: `JavaScript jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Top JavaScript Jobs Worldwide",
        subHeading: "Job board made and curated by JavaScript developers",
      },
    },
    query: {},
  },
  {
    path: "/junior-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Junior developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Junior Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Junior JavaScript Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["junior-level"],
    },
  },
  {
    path: "/mid-level-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Mid-weight developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Mid-weight Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Mid-weight JavaScript Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["mid-level"],
    },
  },
  {
    path: "/senior-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Senior developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Senior Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Senior JavaScript Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["senior-level"],
    },
  },
  {
    path: "/front-end-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Front End jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Front End Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Front End Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["front-end"],
    },
  },
  {
    path: "/senior-front-end-developer-jobs",
    parentPath: "/front-end-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Senior Front End Developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Senior Front End Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Senior Front End Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["front-end", "senior-level"],
    },
  },
  {
    path: "/junior-front-end-developer-jobs",
    parentPath: "/front-end-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Junior Front End Developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Junior Front End Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Junior Front End Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["front-end", "junior-level"],
    },
  },
  {
    path: "/full-stack-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Full Stack developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Full Stack Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Full Stack Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["full-stack"],
    },
  },
  {
    path: "/senior-full-stack-developer-jobs",
    parentPath: "/full-stack-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Senior Full Stack developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Senior Full Stack Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Senior Full Stack Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["full-stack", "senior-level"],
    },
  },
  {
    path: "/react-developer-jobs",
    parentPath: "/front-end-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `React developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to React Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "React Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["react"],
    },
  },
  {
    path: "/senior-react-developer-jobs",
    parentPath: "/react-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Senior React developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Senior React Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Senior React Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["react", "senior-level"],
    },
  },
  {
    path: "/react-native-developer-jobs",
    parentPath: "/react-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `React Native developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to React Native Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "React Native Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["react-native"],
    },
  },
  {
    path: "/typescript-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `TypeScript developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to TypeScript Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "TypeScript Jobs Worldwide",
        subHeading: "Curated by TypeScript developers",
      },
    },
    query: {
      tags: ["typescript"],
    },
  },
  {
    path: "/node-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Node.js developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Node.js Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Node.js Jobs Worldwide",
        subHeading: "Curated by TypeScript developers",
      },
    },
    query: {
      tags: ["node"],
    },
  },
  {
    path: "/back-end-developer-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Back End developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Back End Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Back End Jobs Worldwide",
        subHeading: "Curated by TypeScript developers",
      },
    },
    query: {
      tags: ["back-end"],
    },
  },
  {
    path: "/angular-developer-jobs",
    parentPath: "/front-end-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Angular developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Angular Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Angular Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["angular"],
    },
  },
  {
    path: "/vue-developer-jobs",
    parentPath: "/front-end-developer-jobs",
    relatedPaths: [],
    page: {
      header: {
        title: `Vue.js developer jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Vue.js Developer jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Vue.js Jobs Worldwide",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      tags: ["vue"],
    },
  },
] as SeoPage[];
