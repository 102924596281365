export const regions = {
  ASIA: {
    name: "Asia",
    countries: [
      "AE",
      "AF",
      "AM",
      "AZ",
      "BD",
      "BH",
      "BN",
      "BT",
      "CC",
      "CN",
      "CX",
      "GE",
      "HK",
      "ID",
      "IL",
      "IN",
      "IO",
      "IQ",
      "IR",
      "JO",
      "JP",
      "KG",
      "KH",
      "KP",
      "KR",
      "KW",
      "KZ",
      "LA",
      "LB",
      "LK",
      "MM",
      "MN",
      "MO",
      "MV",
      "MY",
      "NP",
      "OM",
      "PH",
      "PK",
      "PS",
      "QA",
      "SA",
      "SG",
      "SY",
      "TH",
      "TJ",
      "TM",
      "TR",
      "TW",
      "UZ",
      "VN",
      "YE",
    ],
  },
  OCEANIA: {
    name: "Oceania",
    countries: [
      "AS",
      "AU",
      "CK",
      "FJ",
      "FM",
      "GU",
      "KI",
      "MH",
      "MP",
      "NC",
      "NF",
      "NR",
      "NU",
      "NZ",
      "PF",
      "PG",
      "PN",
      "PW",
      "SB",
      "TK",
      "TL",
      "TO",
      "TV",
      "UM",
      "VU",
      "WF",
      "WS",
    ],
  },
  AFRICA: {
    name: "Africa",
    countries: [
      "AO",
      "BF",
      "BI",
      "BJ",
      "BW",
      "CD",
      "CF",
      "CG",
      "CI",
      "CM",
      "CV",
      "DJ",
      "DZ",
      "EG",
      "EH",
      "ER",
      "ET",
      "GA",
      "GH",
      "GM",
      "GN",
      "GQ",
      "GW",
      "KE",
      "KM",
      "LR",
      "LS",
      "LY",
      "MA",
      "MG",
      "ML",
      "MR",
      "MU",
      "MW",
      "MZ",
      "NA",
      "NE",
      "NG",
      "RE",
      "RW",
      "SC",
      "SD",
      "SH",
      "SL",
      "SN",
      "SO",
      "SS",
      "ST",
      "SZ",
      "TD",
      "TG",
      "TN",
      "TZ",
      "UG",
      "YT",
      "ZA",
      "ZM",
      "ZW",
    ],
  },
  EUROPE: {
    name: "Europe",
    countries: [
      "AD",
      "AL",
      "AT",
      "AX",
      "BA",
      "BE",
      "BG",
      "BY",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FO",
      "FR",
      "GB",
      "GG",
      "GI",
      "GR",
      "HR",
      "HU",
      "IE",
      "IM",
      "IS",
      "IT",
      "JE",
      "LI",
      "LT",
      "LU",
      "LV",
      "MC",
      "MD",
      "ME",
      "MK",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RS",
      "RU",
      "SE",
      "SI",
      "SJ",
      "SK",
      "SM",
      "UA",
      "VA",
      "XK",
    ],
  },
  EUROPEAN_UNION: {
    name: "European Union",
    countries: [
      "AT",
      "BE",
      "BG",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GR",
      "HR",
      "HU",
      "IE",
      "IT",
      "LT",
      "LU",
      "LV",
      "MT",
      "NL",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
    ],
  },
  NORTH_AMERICA: {
    name: "North America",
    countries: [
      "AG",
      "AI",
      "AW",
      "BB",
      "BL",
      "BM",
      "BQ",
      "BS",
      "BZ",
      "CA",
      "CR",
      "CU",
      "CW",
      "DM",
      "DO",
      "GD",
      "GL",
      "GP",
      "GT",
      "HN",
      "HT",
      "JM",
      "KN",
      "KY",
      "LC",
      "MF",
      "MQ",
      "MS",
      "MX",
      "NI",
      "PA",
      "PM",
      "PR",
      "SV",
      "SX",
      "TC",
      "TT",
      "US",
      "VC",
      "VG",
      "VI",
    ],
  },
  SOUTH_AMERICA: {
    name: "South America",
    countries: [
      "AR",
      "BO",
      "BR",
      "CL",
      "CO",
      "EC",
      "FK",
      "GF",
      "GY",
      "PE",
      "PY",
      "SR",
      "UY",
      "VE",
    ],
  },
  get AMERICAS() {
    return {
      name: "Americas",
      countries: [
        ...this.NORTH_AMERICA.countries,
        ...this.SOUTH_AMERICA.countries,
      ],
    };
  },
};

export const timeZones = {
  "UTC-11": { countries: ["NU", "AS", "UM"] },
  "UTC-10": { countries: ["US", "UM", "CK", "PF"] },
  "UTC-9": { countries: ["US", "PF"] },
  "UTC-8": { countries: ["US", "MX", "CA", "PN"] },
  "UTC-7": { countries: ["US", "CA", "MX"] },
  "UTC-6": {
    countries: [
      "MX",
      "BZ",
      "US",
      "CR",
      "SV",
      "GT",
      "NI",
      "CA",
      "HN",
      "CL",
      "EC",
    ],
  },
  "UTC-5": {
    countries: [
      "CO",
      "MX",
      "US",
      "BR",
      "TC",
      "EC",
      "CU",
      "CA",
      "JM",
      "PE",
      "PA",
      "KY",
      "HT",
      "BS",
    ],
  },
  "UTC-4": {
    countries: [
      "PY",
      "BB",
      "BR",
      "VE",
      "CA",
      "GY",
      "BO",
      "MQ",
      "PR",
      "AG",
      "AI",
      "AW",
      "BL",
      "BQ",
      "CW",
      "DM",
      "GD",
      "GP",
      "KN",
      "LC",
      "MF",
      "MS",
      "SX",
      "TT",
      "VC",
      "VG",
      "VI",
      "CL",
      "DO",
      "GL",
      "BM",
    ],
  },
  "UTC-3": {
    countries: ["BR", "AR", "GF", "PM", "UY", "GL", "SR", "CL", "AQ", "FK"],
  },
  "UTC-2": { countries: ["BR", "GS"] },
  "UTC-1": { countries: ["GL", "PT", "CV"] },
  UTC: {
    countries: [
      "CI",
      "BF",
      "GH",
      "GM",
      "GN",
      "ML",
      "MR",
      "SH",
      "SL",
      "SN",
      "TG",
      "GW",
      "LR",
      "ST",
      "GL",
      "AQ",
      "ES",
      "FO",
      "PT",
      "IS",
      "GB",
      "GG",
      "IM",
      "JE",
    ],
  },
  "UTC+1": {
    countries: [
      "DZ",
      "MA",
      "ES",
      "EH",
      "NG",
      "AO",
      "BJ",
      "CD",
      "CF",
      "CG",
      "CM",
      "GA",
      "GQ",
      "NE",
      "TD",
      "TN",
      "NL",
      "AD",
      "RS",
      "BA",
      "HR",
      "ME",
      "MK",
      "SI",
      "DE",
      "BE",
      "HU",
      "DK",
      "IE",
      "GI",
      "LU",
      "MT",
      "MC",
      "NO",
      "SJ",
      "BV",
      "FR",
      "CZ",
      "SK",
      "IT",
      "SM",
      "VA",
      "SE",
      "AL",
      "AT",
      "PL",
      "CH",
      "LI",
    ],
  },
  "UTC+2": {
    countries: [
      "EG",
      "ZA",
      "LS",
      "SZ",
      "SS",
      "SD",
      "MZ",
      "BI",
      "BW",
      "CD",
      "MW",
      "RW",
      "ZM",
      "ZW",
      "LY",
      "NA",
      "JO",
      "LB",
      "SY",
      "CY",
      "PS",
      "IL",
      "GR",
      "RO",
      "MD",
      "FI",
      "AX",
      "RU",
      "UA",
      "LV",
      "BG",
      "EE",
      "LT",
    ],
  },
  "UTC+3": {
    countries: [
      "KE",
      "DJ",
      "ER",
      "ET",
      "KM",
      "MG",
      "SO",
      "TZ",
      "UG",
      "YT",
      "IQ",
      "QA",
      "BH",
      "SA",
      "AQ",
      "KW",
      "YE",
      "TR",
      "RU",
      "BY",
      "UA",
    ],
  },
  "UTC+4": {
    countries: ["AZ", "AE", "OM", "GE", "AM", "RU", "SC", "MU", "RE", "TF"],
  },
  "UTC+5": {
    countries: ["AQ", "KZ", "TM", "TJ", "PK", "UZ", "RU", "TF", "HM", "MV"],
  },
  "UTC+6": { countries: ["AQ", "KZ", "KG", "BD", "RU", "BT", "CN", "IO"] },
  "UTC+7": {
    countries: ["AQ", "TH", "KH", "LA", "VN", "RU", "MN", "ID", "CX"],
  },
  "UTC+8": {
    countries: [
      "BN",
      "MN",
      "HK",
      "RU",
      "MY",
      "MO",
      "ID",
      "PH",
      "CN",
      "SG",
      "TW",
      "AU",
    ],
  },
  "UTC+9": { countries: ["RU", "TL", "ID", "KP", "KR", "JP", "PW"] },
  "UTC+10": { countries: ["AU", "RU", "FM", "GU", "MP", "PG", "AQ"] },
  "UTC+11": { countries: ["AQ", "RU", "PG", "VU", "SB", "FM", "NF", "NC"] },
  "UTC+12": {
    countries: ["RU", "NZ", "AQ", "FJ", "TV", "MH", "NR", "KI", "UM", "WF"],
  },
  "UTC+13": { countries: ["WS", "TK", "KI", "TO"] },
  "UTC+14": { countries: ["KI"] },
};
