import { SeoPage } from "./seoPages";
import { regions } from "./onsite/regions";
import { eu } from "./onsite/eu";
import { fr } from "./onsite/fr";
import { ca } from "./onsite/ca";
import { es } from "./onsite/es";
import { uk } from "./onsite/uk";
import { us } from "./onsite/us";
import { de } from "./onsite/de";
import { au } from "./onsite/au";
import { pt } from "./onsite/pt";
import { it } from "./onsite/it";
import { be } from "./onsite/be";
import { cz } from "./onsite/cz";

export const onsite = [
  ...regions,
  ...us,
  ...ca,
  ...eu,
  ...uk,
  ...es,
  ...pt,
  ...fr,
  ...it,
  ...de,
  ...au,
  ...be,
  ...cz,
] as SeoPage[];
