import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const us = [
  {
    path: "/us",
    parentPath: "/",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in the United States in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in the United States on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in the United States",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/san-francisco",
    parentPath: "/us",
    relatedPaths: ["/remote/us", "/us/los-angeles", "/us/new-york"],
    page: {
      header: {
        title: `JavaScript jobs in San Francisco, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in San Francisco, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in San Francisco, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:san francisco",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/los-angeles",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in Los Angeles, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Los Angeles, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Los Angeles, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:los angeles",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/new-york",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in New York, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in New York, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in New York, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:new york",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/austin",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in Austin, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Austin, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Austin, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:austin",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/miami",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in Miami, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Miami, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Miami, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:miami",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/seattle",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in Seattle, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Seattle, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Seattle, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:seattle",
      remoteLocationTags: "country_code:us",
    },
  },
  {
    path: "/us/chicago",
    parentPath: "/us",
    relatedPaths: ["/remote/us"],
    page: {
      header: {
        title: `JavaScript jobs in Chicago, USA in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Chicago, USA on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Chicago, USA",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:us, locality_long_name:chicago",
      remoteLocationTags: "country_code:us",
    },
  },
] as SeoPage[];
