import { FC } from "react";
import Link from "next/link";
import LogoSvg from "@public/jobsinjs-logo-1.svg";
import { classNames } from "@utils/classNames";

interface Props {
  logoCN?: string;
}

export const Logo: FC<Props> = ({ logoCN }) => (
  (<Link href="/" className="flex-shrink-0 flex items-center" title="Jobs in JS">

    <LogoSvg className={classNames("block fill-current", logoCN)} />

  </Link>)
);
