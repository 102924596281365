import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const cz = [
  {
    path: "/cz",
    parentPath: "/",
    relatedPaths: ["/cz/prague", "/cz/brno", "/remote/cz"],
    page: {
      header: {
        title: `JavaScript jobs in Czechia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Czechia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Czechia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:cz",
      remoteLocationTags: "country_code:cz",
    },
  },
  {
    path: "/cz/prague",
    parentPath: "/cz",
    relatedPaths: ["/cz/brno", "/remote/cz"],
    page: {
      header: {
        title: `JavaScript jobs in Prague, Czechia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Prague, Czechia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Prague, Czechia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:cz, locality_long_name:prague",
      remoteLocationTags: "country_code:cz",
    },
  },
  {
    path: "/cz/brno",
    parentPath: "/cz",
    relatedPaths: ["/cz/prague", "/remote/cz"],
    page: {
      header: {
        title: `JavaScript jobs in Brno, Czechia in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Brno, Czechia on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Brno, Czechia",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:cz, locality_long_name:brno",
      remoteLocationTags: "country_code:cz",
    },
  },
] as SeoPage[];
