import { FC } from "react";
import { Session } from "@auth0/nextjs-auth0";
import { Logo } from "./Logo";
import { PrimaryNavigation } from "./PrimaryNavigation";
import { PostJobButton } from "./PostJobButton";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { HeaderSearch } from "@components/JobPostSearchModal/HeaderSearch";

interface Props {
  user: Session["user"];
  hasSearch?: boolean;
}

export const Header: FC<Props> = ({ user, hasSearch = false }) => {
  return (
    <div className="relative flex justify-between h-20 items-center px-4 border-b shadowX sm:px-6">
      {/* Left side */}
      <div className="flex z-10">
        <Logo logoCN="h-10 text-[#3333FF] hover:text-[#0000F2]" />
        <PrimaryNavigation />
      </div>

      {/* Middle */}
      {hasSearch && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-0">
          <div className="flex justify-center items-center h-full">
            <HeaderSearch />
          </div>
        </div>
      )}

      {/* Right side */}
      <div className="flex z-10">
        <div className="hidden ml-3 md:flex">
          <PostJobButton cN="rounded hover:bg-gray-100" />
        </div>
        <SecondaryNavigation user={user} />
      </div>
    </div>
  );
};
