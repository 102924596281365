import { format } from "date-fns";
import { SeoPage } from "./seoPages";

export const collections = [
  {
    path: "/aerospace-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Aerospace jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Aerospace jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Aerospace companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["aerospace"],
    },
  },
  {
    path: "/automotive-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Automotive jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Automotive jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Automotive companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["automotive"],
    },
  },
  {
    path: "/blockchain-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Blockchain jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Blockchain jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Blockchain companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["blockchain"],
    },
  },
  {
    path: "/creative-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Creative jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Creative jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Creative companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["creative"],
    },
  },
  {
    path: "/crypto-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Cryptocurrency jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Cryptocurrency jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Cryptocurrency companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["crypto"],
    },
  },
  {
    path: "/data-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Data jobs in ${format(new Date(), "LLLL yyyy")} | JobsinJS.com`,
        description:
          "Apply to Data jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Data companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["data"],
    },
  },
  {
    path: "/developer-tools-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Developer Tools jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Developer Tools jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Developer Tools companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["developer-tools"],
    },
  },
  {
    path: "/finance-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Finance jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Finance jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Finance companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["finance"],
    },
  },
  {
    path: "/fashion-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Fashion jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Fashion jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Fashion companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["fashion"],
    },
  },
  {
    path: "/gaming-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Gaming jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Gaming jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Gaming companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["games"],
    },
  },
  {
    path: "/healthcare-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Healthcare jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Healthcare jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Healthcare companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["healthcare"],
    },
  },
  {
    path: "/human-resources-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Human Resources jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Human Resources jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Human Resources companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["human-resources"],
    },
  },
  {
    path: "/marketing-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Marketing jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Marketing jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Marketing companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["marketing"],
    },
  },
  {
    path: "/media-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Media jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Media jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Media companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["media"],
    },
  },
  {
    path: "/metaverse-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Metaverse jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Metaverse jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Metaverse companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["metaverse"],
    },
  },
  {
    path: "/music-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Music jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Music jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Music companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["music"],
    },
  },
  {
    path: "/nft-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `NFT jobs in ${format(new Date(), "LLLL yyyy")} | JobsinJS.com`,
        description:
          "Apply to NFT jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "NFT companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["nft"],
    },
  },
  {
    path: "/no-code-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `No-Code jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to No-Code jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "No-Code companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["no-code"],
    },
  },
  {
    path: "/non-profit-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Non-Profit jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Non-Profit jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Non-Profit organisations hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["non-profit"],
    },
  },
  {
    path: "/productivity-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Productivity jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Productivity jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Productivity companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["productivity"],
    },
  },
  {
    path: "/real-estate-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Real Estate jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Real Estate jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Real Estate companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["real-estate"],
    },
  },
  {
    path: "/retail-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Retail jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Retail jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Retail companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["retail"],
    },
  },
  {
    path: "/robotics-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Robotics jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Robotics jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Robotics companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["robotics"],
    },
  },
  {
    path: "/saas-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `SaaS jobs in ${format(new Date(), "LLLL yyyy")} | JobsinJS.com`,
        description:
          "Apply to SaaS jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "SaaS companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["saas"],
    },
  },
  {
    path: "/security-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Security jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Security jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Security companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["security"],
    },
  },
  {
    path: "/social-media-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Social Media jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Social Media jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Social Media companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["social-media"],
    },
  },
  {
    path: "/software-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Software jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Software jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Software companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["software"],
    },
  },
  {
    path: "/sports-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Sports jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Sports jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Sports companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["sports"],
    },
  },
  {
    path: "/sustainability-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Sustainability jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Sustainability jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Sustainability companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["sustainability"],
    },
  },
  {
    path: "/tech-for-good-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Tech-for-Good jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Tech-for-Good jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Tech-for-Good companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["tech-for-good"],
    },
  },
  {
    path: "/telecomunication-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Telecomunication jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Telecomunication jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Telecomunication companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["telecomunication"],
    },
  },
  {
    path: "/transportation-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Transportation jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Transportation jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Transportation companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["transportation"],
    },
  },
  {
    path: "/travel-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `Travel jobs in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Travel jobs now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "Travel companies hiring JavaScript developers",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["travel"],
    },
  },
  {
    path: "/visa-sponsorship-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `JavaScript jobs providing visa sponsorship in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to JavaScript jobs providing visa sponsorship now hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs providing visa sponsorship",
        subHeading: "Curated by JavaScript developers.",
      },
    },
    query: {
      tags: ["visa-sponsorship"],
    },
  },
  {
    path: "/4-day-week-jobs",
    parentPath: "/",
    relatedPaths: [],
    page: {
      header: {
        title: `4 day work week JavaScript jobs hiring in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to 4 day work week JavaScript jobs hiring on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "4 day week jobs hiring JavaScript developers",
        subHeading: "Find jobs with a 4 day work week (mostly 32hrs)",
      },
    },
    query: {
      tags: ["4-day-week"],
    },
  },
] as SeoPage[];
