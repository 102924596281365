import { format } from "date-fns";
import { SeoPage } from "../seoPages";

export const es = [
  {
    path: "/es",
    parentPath: "/",
    relatedPaths: ["/remote/es"],
    page: {
      header: {
        title: `JavaScript jobs in Spain in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Spain on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Spain",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:es",
      remoteLocationTags: "country_code:es",
    },
  },
  {
    path: "/es/barcelona",
    parentPath: "/es",
    relatedPaths: ["/es/madrid", "/remote/es"],
    page: {
      header: {
        title: `JavaScript jobs in Barcelona, Spain in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Barcelona, Spain on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Barcelona, Spain",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:es, locality_long_name:barcelona",
      remoteLocationTags: "country_code:es",
    },
  },
  {
    path: "/es/madrid",
    parentPath: "/es",
    relatedPaths: ["/es/barcelona", "/remote/es"],
    page: {
      header: {
        title: `JavaScript jobs in Madrid, Spain in ${format(
          new Date(),
          "LLLL yyyy"
        )} | JobsinJS.com`,
        description:
          "Apply to Javascript Developer jobs now hiring in Madrid, Spain on JobsinJS.com, the worlds largest JavaScript job site.",
      },
      body: {
        heading: "JavaScript jobs hiring in Madrid, Spain",
        subHeading: "Curated by JavaScript developers",
      },
    },
    query: {
      onsiteLocationTags: "country_code:es, locality_long_name:madrid",
      remoteLocationTags: "country_code:es",
    },
  },
] as SeoPage[];
